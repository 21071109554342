/*Google Fonts Family importada*/
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif; /*Criando CSS file Global usando a Font Outfit do Google Font*/
}

body{
  background: black;
}

h1,h4,h2,p,a{
  color: white;
  text-decoration: none; /*Remove underline dos links*/
}

ul{
  list-style-type: none;
}

/* Os proximos 3x styles com .btn são para definir o estilo dos button components */
.button {
  padding: 12px 32px;
  font-size: 1rem;
  font-style: oblique;
  text-transform: uppercase;
  background: yellow;
  color: #222;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
}

.button-light{
  background: transparent;
  color: white;
}

.button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transition: 0.3s;
}