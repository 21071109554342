.recommendation-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.recommendation-container{
    /*position: sticky;*/
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(8, 180px);
    grid-gap: 5px;
    padding-bottom: 2rem;
    padding-left: 10rem;
    padding-right: 10rem;
}

.recommendation-card{
    background-color: rgb(17, 16, 16);
    padding: 1.2rem 1rem;
}

.recommendation-card img{
    width: 100%;
}

.recommendation-name{
    text-align: center;
    margin: 0;
}

h2.recommendation-name{
    font-size: 1.1rem;
}

.recommendation-description{
    margin: 0;
}

.recommendation-datetime{
    padding-bottom: 0rem;
    font-size: smaller;
    text-align: left;
}

.recommendation-address{
    padding-bottom: 0rem;
    font-size: smaller;
    text-align: left;
}

.recommendation-button{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.recommendation-button .btn{
    padding: 0.5rem 1rem;
}