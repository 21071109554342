.events-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.events-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.events-card{
    background-color: rgb(17, 16, 16);
    padding: 1.2rem 1rem;
}

.events-card img{
    width: 100%;
}

.events-name{
    text-align: center;
    padding: 1rem 0;
}

.events-description{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

.events-datetime{
    padding-bottom: 2rem;
    font-size: 2rem;
    text-align: justify;
}

.events-address{
    padding-bottom: 2rem;
    font-size: 2rem;
    text-align: justify;
}

.event-button{
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
}

.event-button .btn{
    padding: 0.5rem 1rem;
}