.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after{
    content:"";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.6; /*Configurar o nivel de transparencia da imagem Hero Banner*/
}

.hero{
    height: 100%;
    width: 100%;
}

.hero .content{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 75%;
    left: 50%;
    text-align: center;
}

.hero .content h1{
    font-size: 3.5rem;
}